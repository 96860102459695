var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "AppLayout",
    {
      scopedSlots: _vm._u([
        {
          key: "actionbar",
          fn: function() {
            return [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex align-items-start justify-content-between"
                },
                [
                  _c("div", { staticClass: "d-flex align-items-center" }, [
                    _c(
                      "div",
                      { staticClass: "text-center d-inline-block" },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "btn-circle mb-1 px-0",
                            staticStyle: { padding: "0.1rem 3.5rem" },
                            attrs: { variant: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.$router.back()
                              }
                            }
                          },
                          [_c("i", { staticClass: "tmlps-icn-back h3" })]
                        ),
                        _c("div", { staticClass: "small text-center" }, [
                          _vm._v(_vm._s(_vm.$t("lang.return.msg")))
                        ])
                      ],
                      1
                    )
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "d-flex justify-content-center flex-fill",
                      staticStyle: { "padding-left": "21vw" }
                    },
                    [
                      _c(
                        "div",
                        [
                          _c("PlayButton", {
                            attrs: { value: _vm.playing },
                            on: { input: _vm.handlePlay }
                          })
                        ],
                        1
                      ),
                      _c("div", { staticClass: "text-center ml-4" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn-circle btn-line mb-1",
                            attrs: { type: "button" },
                            on: { click: _vm.handleDownload }
                          },
                          [_c("i", { staticClass: "tmlps-icn-download h2" })]
                        ),
                        _c("div", { staticClass: "small" }, [
                          _vm._v(_vm._s(_vm.$t("lang.download.msg")))
                        ])
                      ])
                    ]
                  ),
                  _c("div", [
                    _c("div", { staticClass: "time-control ml-4" }, [
                      _c("input", {
                        ref: "rangeInputRef",
                        attrs: {
                          type: "range",
                          step: "any",
                          min: "0",
                          max: _vm.duration
                        },
                        domProps: { value: _vm.currentTime },
                        on: { input: _vm.handleSeek }
                      }),
                      _c("div", { staticClass: "time-control__label" }, [
                        _vm._v(_vm._s(_vm.formattedCurrentTime))
                      ])
                    ])
                  ])
                ]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [_c("video", { ref: "videoRef", attrs: { src: _vm.video.url } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }