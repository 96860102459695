import apiClient from './api-client';

export default {
  getVideo(id) {
    return apiClient.get(`/videos/${id}`);
  },
  deleteVideo(id) {
    return apiClient.delete(`/videos/${id}`);
  },
};
