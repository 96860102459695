<template>
  <AppLayout>
    <video :src="video.url" ref="videoRef"></video>

    <template v-slot:actionbar>
      <div class="d-flex align-items-start justify-content-between">
        <div class="d-flex align-items-center">
          <div class="text-center d-inline-block">
            <b-button
              class="btn-circle mb-1 px-0"
              variant="primary"
              style="padding: 0.1rem 3.5rem"
              @click="$router.back()"
            >
              <i class="tmlps-icn-back h3"></i>
            </b-button>
            <div class="small text-center">{{$t('lang.return.msg')}}</div>
          </div>
        </div>

        <div class="d-flex justify-content-center flex-fill" style="padding-left: 21vw;">
          <div>
            <PlayButton :value="playing" @input="handlePlay"></PlayButton>
          </div>
          <div class="text-center ml-4">
            <button type="button" class="btn-circle btn-line mb-1" @click="handleDownload">
              <i class="tmlps-icn-download h2"></i>
            </button>
            <div class="small">{{$t('lang.download.msg')}}</div>
          </div>
        </div>

        <div>
          <div class="time-control ml-4">
            <input
              type="range"
              step="any"
              min="0"
              :max="duration"
              :value="currentTime"
              @input="handleSeek"
              ref="rangeInputRef"
            />
            <div class="time-control__label">{{ formattedCurrentTime }}</div>
          </div>
        </div>
      </div>
    </template>
  </AppLayout>
</template>

<script>
import videoService from '@/services/video';
import AppLayout from '@/layouts/AppLayout.vue';
import PlayButton from '@/components/PlayButton.vue';

export default {
  name: 'VideoPlayer',
  components: { AppLayout, PlayButton },
  props: {
    video: { type: Object, required: true },
  },
  data() {
    return {
      playing: false,
      duration: 0,
      currentTime: 0,
    };
  },
  computed: {
    formattedCurrentTime() {
      const minutes = parseInt(this.currentTime / 60, 10);
      const seconds = parseInt(this.currentTime - minutes * 60, 10);

      return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    },
  },
  methods: {
    async handleDownload() {
      let blob = await fetch(this.video.url, { cache: 'no-cache' }).then((response) =>
        response.blob(),
      );
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = this.video.filename;
      link.click();
    },
    handlePlay(value) {
      if (this.playing) {
        this.$refs.videoRef.pause();
      } else {
        this.$refs.videoRef.play();
      }
      this.playing = value;
    },
    handleSeek(event) {
      this.currentTime = event.target.value;
      this.$refs.videoRef.currentTime = event.target.value;
    },
  },

  async beforeRouteEnter(to, from, next) {
    to.params.video = await videoService
      .getVideo(to.params.id)
      .then((response) => response.data.data);

    next();
  },
  async beforeRouteUpdate(to, from, next) {
    this.video = await videoService.getVideo(to.params.id).then((response) => response.data.data);

    next();
  },

  mounted() {
    const vm = this;

    this.$refs.videoRef.addEventListener('timeupdate', function(event) {
      vm.currentTime = event.target.currentTime;
    });
    this.$refs.videoRef.addEventListener('ended', function() {
      vm.playing = false;
    });
    this.$refs.videoRef.addEventListener('canplay', (event) => {
      vm.duration = event.target.duration;
    });
  },
};
</script>

<style lang="scss" scoped>
video {
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 100%;
}

.time-control {
  padding-left: 1rem;
  padding-right: 0.5rem;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  background-color: white;
  box-shadow: 0 0 1rem 1px rgb(0 0 0 / 18%);
  border-radius: 9999px;
  min-width: 21rem;

  &__label {
    font-size: 0.625rem;
    text-align: right;
  }

  input[type='range'] {
    width: 100%;
  }

  input[type='range'],
  input[type='range']::-webkit-slider-runnable-track,
  input[type='range']::-webkit-slider-thumb {
    appearance: none;
    -webkit-appearance: none;
  }
  input[type='range']:focus {
    outline: none;
  }

  input[type='range']::-webkit-slider-thumb {
    appearance: none;
    background-color: var(--primary);
    width: 15px;
    height: 15px;
    border: 3px solid var(--primary);
    border-radius: 9999px;
    margin-top: -6px;
  }
  input[type='range']::-moz-range-thumb {
    background-color: var(--primary);
    width: 10px;
    height: 10px;
    border: 3px solid var(--primary);
    border-radius: 9999px;
  }
  input[type='range']::-ms-thumb {
    background-color: var(--primary);
    width: 15px;
    height: 15px;
    border: 3px solid var(--primary);
    border-radius: 9999px;
  }

  input[type='range']::-webkit-slider-runnable-track {
    background-color: var(--primary);
    height: 3px;
  }
  input[type='range']::-moz-range-track {
    background-color: var(--primary);
    height: 3px;
  }
  input[type='range']::-ms-track {
    background-color: var(--primary);
    height: 3px;
  }
  input[type='range']::-ms-fill-lower {
    background-color: HotPink;
  }
  input[type='range']::-ms-fill-upper {
    background-color: black;
  }
}
</style>
