<template>
  <div class="d-inline-flex flex-column align-items-center justify-content-center">
    <button
      type="button"
      @click="handleClick"
      :disabled="disabled"
      class="btn-circle btn-line mb-1"
    >
      <i v-if="isPlaying" class="tmlps-icn-pause h2 m-0" :class="isPlaying ? 'color-text' : ''"></i>
      <i v-else class="tmlps-icn-play h2 m-0" :class="isPlaying ? 'color-text' : ''"></i>
    </button>
    <div class="play-button__label small text-center" :class="disabled ? 'text-muted' : ''">
      {{ label }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'PlayButton',
  props: {
    value: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  computed: {
    isPlaying() {
      return this.value === true;
    },
    label() {
      return this.isPlaying ? this.$t('lang.pause.msg') : this.$t('lang.play.msg');
    },
  },
  methods: {
    handleClick() {
      const newValue = !this.value;

      if (newValue === true) {
        this.$emit('play', newValue);
      } else {
        this.$emit('pause', newValue);
      }

      this.$emit('input', newValue);
    },
  },
};
</script>
